<template>
    <Modal id="autoLogoutConfirmationModal" :width="`644px`">
        <template #body>
            <div class="flex flex-column">
                <div class="flex justify-content-between align-items-center title">
                    <h3 class="blue">YOU’RE NOW LOGGED OUT!</h3>
                    <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span></Button>
                </div>
            </div>

            <div class="flex content">
                <div class="flex flex-column" style="width:440px;">
                    <p class="body-text dark-grey">The clarity app logs you out automatically if you don't use the service for <span class="blue"><b>30 minutes</b></span>.</p>
                    <p class="body-text dark-grey">This gives you added protection if you forget to log yourself out.</p>
                    <p class="body-text dark-grey">If you were drafting a secure message, please log back in with the same account to recover the message.</p>
                    <Button @click="closeModal" class="clarity-btn clarity-gradient-orange" style="width:130px;" label="Close"></Button>
                </div>
                <div class="flex flex-column">
                    <img src="@/assets/img/autologout.png" alt="Logout Confirmation">
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const closeModal = () => {
            store.dispatch('setActiveModal');
        }

        return {
            closeModal
        }
    },
    components: {
        Modal
    }
}
</script>

<style scoped>
    div:deep(.modal-close) {
        display: none;
    }
    div:deep(.modal-body) {
        overflow-x: hidden !important;
    }
    .title {
        padding: 30px 30px 0px 30px;
    }
    .content {
        padding: 30px 30px 30px 30px;
    }
</style>
