<template>
    <div>
        <div>
            <h2 class="clarity-h2 blue">Logging out<br>please wait</h2>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Auth } from '@aws-amplify/auth';

    export default {
        setup() {
            const store = useStore();
            const router = useRouter();

            const logoutUser = async () => {
                const user = await store.dispatch('checkUserAuth')
                //console.log(user);
                if(!user) {
                    router.push('/');
                } else {
                    const URI = decodeURIComponent(window.location.href);
                    const params = new URL(URI).searchParams;
                    const siteReferer = params.get('s');
                    await Auth.signOut();
                    store.commit('resetCognitoUser');
                    const host = window.location.hostname;
                    let domain = '';
                    if(host.includes("clarityglobal")) {
                        domain = host.slice(-17);
                    } else {
                        domain = host;
                    }
                    document.cookie = "rd_authenticated=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain="+domain+";";
                    if(siteReferer === 'sales') {
                        window.location.assign('https://www.clarityglobal.com/homepage');
                    } else if(siteReferer === 'autologout') {
                        store.dispatch('autoLogout');
                        router.push('/');
                    } else {
                        router.push('/');
                    }
                }
            }

            onMounted(() => {
                logoutUser();
            });

            return {
                logoutUser,
            }
        }
    }
</script>

<style>
    h2 {
        text-align: center;
    }
</style>
