<template>
    <div class="flex flex-column">
        <h2 class="clarity-h2 blue clarity-margin-bottom-2xs">WELCOME BACK</h2>
        <div id="loginForm">
            <div v-if="showLoginErrorMessage" class="flex flex-column">
                <div class="create-error-msg">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <h3 class="red"><span class="material-icons">error</span>LOGIN FAILED</h3>
                        </div>
                        <div class="flex grey cursor-pointer" @click="closeLoginError">
                            <span class="material-icons-outlined">close</span>
                        </div>
                    </div>
                    <p class="body-text">{{ errorMessage }}</p>
                    <!-- <p class="body-text">Incorrect username or password.</p> -->
                </div>
            </div>
                <div class="formgrid grid" data-cy="login-form">
                    <div class="field col p-input-icon-left p-input-icon-right cl-input usernameInput">
                        <i class="pi cl-account-icon" />
                        <InputText :class="{ mfaCodeErrorInput: usernameError}" type="email" placeholder="Username..." class="p-inputtext-lg w-full" v-model="email" data-cy="email-input" />
                        <i v-if="usernameError" class="pi cl-error-icon" />
                    </div>
                </div>
                <p v-if="usernameError" class="inputErr">Incorrect Username</p>
                <div class="formgrid grid">
                    <div class="field col p-input-icon-left p-input-icon-right cl-input">
                        <i class="pi cl-password-icon" />
                      <InputText v-if="showPassword" :class="{ mfaCodeErrorInput: passwordError}" type="text" class="p-inputtext-lg w-full" v-model="password" />
                      <InputText v-else :class="{ mfaCodeErrorInput: passwordError}" type="password" placeholder="Password..." class="p-inputtext-lg w-full" v-model="password" data-cy="password-input"  @keyup.enter="login" />
                      <i v-if="passwordError" class="pi error" :class="{'cl-show-password-grey-icon': showPassword, 'cl-hide-password-grey-icon': !showPassword }" @click="toggleShow" />
                      <i v-else class="pi" :class="{'cl-show-password-grey-icon': showPassword, 'cl-hide-password-grey-icon': !showPassword }" @click="toggleShow" />
                    </div>
                </div>
                <p v-if="passwordError" class="inputErr">Incorrect Password</p>
                <p class="forgottenPasswordLink"><router-link to="/forgottenpassword">Forgotten Password?</router-link></p>
                <div class="flex flex-wrap sm:flex-row align-content-center justify-content-start" style="column-gap: 20px;">
                    <div class="flex flex-grow-1 align-content-center clarity-margin-top-s" style="min-width: 300px;">
                        <submit-button @submitted="login" loadingText="Login" :loading="loadingState" button-text="Login" style="width: 100%;" class="mr-2" data-cy="button-login" ></submit-button>
                    </div>
                    <div class="flex flex-column loginHelpLinks clarity-margin-top-s justify-content-start">
                        <p class="body-text dark-grey">Having trouble signing in?</p>
                        <p @click="showLoginVideo" class="small-text grey loginHelpClick">Find out more about "How to sign in".</p>
                    </div>
                </div>

                <Dialog class="showLoginVideoTour" :modal="true" v-model:visible="displayLoginVideo">
                    <div class="loginVideoDialog">
                        <div class="flex justify-content-between modal-header">
                            <h3 class="clarity-h3 blue">NEED HELP ON HOW TO SIGN IN?</h3>
                            <Button class="clarity-close-modal-btn" @click="closeLoginVideoTour"><span class="material-icons-round grey">close</span></Button>
                        </div>
                        <div>
                            <p class="body-text grey">Need more help? Please contact Admin Team: <span class="material-icons">call</span><b>+44 800 368 7511</b></p>
                            <div class="loginVideo clarity-margin-bottom-s">
                                <div style='padding:56.25% 0 0 0;position:relative;'>
                                    <iframe src='https://player.vimeo.com/video/855748120?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
                                </div>
                            </div>
                            <p class="body-text">Want to know more about the
                                <span class="blue"><b>Multi-Factor Authentication?</b></span>
                                <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityEnablingMFABrochure.pdf" target="_blank">
                                    <span class="material-icons">get_app</span>
                                    <span class="small-text">Download "How to use MFA" Brochure</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </Dialog>

                <hr class="clarity-margin-top-s">
                <p class="notMember">Not a clarity member?</p>
                <router-link to="/createaccount">
                    <Button label="Create an Account" class="mr-2 mb-2 clarity-gradient-grey clarity-btn" style="width: 300px;" data-cy="button-create-account" />
                </router-link>

                <Dialog class="showMFABox" :modal="true" v-model:visible="displayMFA" :style="{width: '600px'}" :breakpoints="{'320px':'100vw'}">
                    <div class="flex justify-content-between modal-header">
                        <h3 class="blue">Clarity Multi-Factor Authentication</h3>
                        <Button class="clarity-close-modal-btn" @click="toggleModal"><span class="material-icons-round grey">close</span></Button>
                    </div>
                    <div>
                        <p class="body-text clarity-margin-left-s">Please enter the <span class="blue code-text-bold">6 digit security code</span> from your<br>Authenticator App.</p>
                    </div>
                    <div v-if="codeError">
                        <div class="codeErrorCard">
                            <div class="flex justify-content-between mfaErrorTitle">
                                <div class="flex">
                                    <h3><span class="material-icons">error</span>VALIDATION FAILED</h3>
                                </div>
                                <div class="flex grey cursor-pointer" @click="closeCodeError">
                                    <span class="material-icons-outlined">close</span>
                                </div>
                            </div>
                            <div>
                                <p class="mfaErrorMsg">The code you entered is not valid. Please try again.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mfaCodeEntry">
                        <h5 class="blue">Security Code:</h5>
                        <InputText autofocus="true" id="boxOne" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaOne" @keyup=nextCodeValue(2) :maxlength="1" />
                        <InputText id="boxTwo" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaTwo" @keyup=nextCodeValue(3) :maxlength="1" />
                        <InputText id="boxThree" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaThree" @keyup=nextCodeValue(4) :maxlength="1" />
                        <InputText id="boxFour" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaFour" @keyup=nextCodeValue(5) :maxlength="1" />
                        <InputText id="boxFive" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaFive" @keyup=nextCodeValue(6) :maxlength="1" />
                        <InputText id="boxSix" class="mfaInputValue" :class="{ mfaCodeErrorInput: codeError}" type="text" v-model="mfaSix" @keyup=nextCodeValue(1) :maxlength="1" />
                    </div>

                    <div class="flex justify-content-between actionBtns">
                        <Button class="clarity-btn clarity-gradient-grey clarity-margin-left-s" @click="toggleModal">Dismiss</Button>
                        <Button id="mfaSubmit" class="clarity-btn clarity-gradient-orange clarity-margin-right-s" @click="validateMFACode">Validate and Login</Button>
                    </div>
                </Dialog>
        </div>
        <AutoLogoutConfirmationModal v-show="showModal === 'AutoLogoutConf'" />
    </div>
</template>

<script>
import {computed, ref, onMounted} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Auth } from '@aws-amplify/auth';
import Dialog from 'primevue/dialog'
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";
import AutoLogoutConfirmationModal from '../components/modals/AutoLogoutConfirmationModal.vue';

    export default {
        components: {Dialog, SubmitButton, AutoLogoutConfirmationModal},
        setup() {
            const store = useStore();
            const router = useRouter();
            const route = useRoute();
            const showModal = computed(() => store.getters.currentActiveModal);
            const email = ref();
            const password = ref('');
            const twoFactor = ref();
            const displayMFA = ref(false);
            const displayLoginVideo = ref(false);
            const codeError = ref(false);
            const showLoginErrorMessage = ref(false);
            const errorMessage = ref('');
            const usernameError = ref(false);
            const passwordError = ref(false);

            const mfaOne = ref('');
            const mfaTwo = ref('');
            const mfaThree = ref('');
            const mfaFour = ref('');
            const mfaFive = ref('');
            const mfaSix = ref('');
            const user = ref();
            const showPassword = ref(false)

            const loadingState = computed(() => store.getters.isLoading('LoginLoading'))

            const login = async () => {
                try {
                    showLoginErrorMessage.value = false;
                    usernameError.value = false;
                    passwordError.value = false;
                    if(email.value === undefined) {
                        errorMessage.value = 'Email can not be empty.';
                        showLoginErrorMessage.value = true;
                        usernameError.value = true;
                    } else {
                        const isEmail = email.value.includes("@");
                        if(!isEmail) {
                        errorMessage.value = 'Username must be an email address.';
                        showLoginErrorMessage.value = true;
                        usernameError.value = true;
                        } else if(password.value === '') {
                            errorMessage.value = 'Password can not be empty.';
                            showLoginErrorMessage.value = true;
                            passwordError.value = true;
                        } else {
                            const data = {email: email.value.toLowerCase(), password: password.value}
                            await store.dispatch('setLoading', {id: 'LoginLoading', state: true})
                            const loginReturn = await store.dispatch('login', data);
                            //console.log(loginReturn);
                            if(loginReturn.code === -1) {
                                await store.dispatch('setLoading', {id: 'LoginLoading', state: false})
                                user.value = loginReturn.value;
                                displayMFA.value = true;
                            } else {
                                store.dispatch('setLoading', {id: 'LoginLoading', state: false})
                                if(store.getters.isAuthenticated) {
                                    const host = window.location.hostname;
                                    let domain = '';
                                    if(host.includes("clarityglobal")) {
                                        domain = host.slice(-17);
                                    } else {
                                        domain = host;
                                    }
                                    const d = new Date();
                                    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
                                    const expires = "expires=" + d.toUTCString();
                                    document.cookie = "rd_authenticated=1;"+ expires +" path=/; domain="+ domain +";";
                                    updateMFApreferenceRecord(false);
                                    goToRoute()
                                }
                            }
                        }
                    }
                } catch (error) {
                    await store.dispatch('setLoading', {id: 'LoginLoading', state: false})
                    if(error.message.includes('User is not confirmed')) {
                        resendComfirmationEmail();
                        errorMessage.value = 'User is not confirmed. Please check your email for a confirmation link.';
                        showLoginErrorMessage.value = true;
                    } else {
                        //console.log(error.message);
                        errorMessage.value = error.message;
                        showLoginErrorMessage.value = true;
                        if(errorMessage.value === 'User does not exist.') {
                            usernameError.value = true;
                            passwordError.value = true;
                        }
                        if(errorMessage.value === 'Username cannot be empty') {
                            usernameError.value = true;
                        }
                    }
                }
            }

            const updateMFApreferenceRecord = async (data) => {
                //
                // This function updates the NG_USER_DATA table with the
                // clients MFA status as the data in the table is not
                // currently accurate.  This function can be taken out
                // once all existing clients have logged in at least once
                //
                store.dispatch('updateSettings', {
                    type: 'settings',
                    loading: 'settings-mfa',
                    title: 'MFA Options',
                    payload: {
                        mfaEnabled: data
                    }
                });
            }

            const resendComfirmationEmail = async () => {
                await Auth.resendSignUp(email.value.toLowerCase());
            }

            const closeCodeError = () => {
                codeError.value = false;
            }

            const toggleModal = () => {
                if(displayMFA.value === true) {
                    displayMFA.value = false;
                }
                else if(displayMFA.value === false) {
                    displayMFA.value = true;
                }
            }

            const validateMFACode = async () => {
                const mfaCode = mfaOne.value + mfaTwo.value + mfaThree.value + mfaFour.value + mfaFive.value + mfaSix.value;
                const data = {mfaCode: mfaCode, cognitoUser:user.value};
                const validateCode = await store.dispatch('validateUserMFA',data);
                if(validateCode !== undefined) {
                    codeError.value = true;
                    mfaOne.value = '';
                    mfaTwo.value = '';
                    mfaThree.value = '';
                    mfaFour.value = '';
                    mfaFive.value = '';
                    mfaSix.value = '';
                } else {
                    displayMFA.value = false;
                }

                if(store.getters.isAuthenticated) {
                    const host = window.location.hostname;
                    let domain = '';
                    if(host.includes("clarityglobal")) {
                        domain = host.slice(-17);
                    } else {
                        domain = host;
                    }
                    const d = new Date();
                    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
                    const expires = "expires=" + d.toUTCString();
                    document.cookie = "rd_authenticated=1;"+ expires +" path=/; domain="+ domain +";";
                    updateMFApreferenceRecord(true);
                    goToRoute()
                }
            }

            const nextCodeValue = (box) => {
                switch (box) {
                    case 1:
                        document.getElementById('mfaSubmit').focus();
                        break;
                    case 2:
                        document.getElementById('boxTwo').focus();
                        break;
                    case 3:
                    document.getElementById('boxThree').focus();
                        break;
                    case 4:
                    document.getElementById('boxFour').focus();
                        break;
                    case 5:
                    document.getElementById('boxFive').focus();
                        break;
                    case 6:
                        document.getElementById('boxSix').focus();
                        break;
                    default:
                        break;
                }
            }

            const closeLoginError = () => {
                if(showLoginErrorMessage.value) {
                    showLoginErrorMessage.value = false;
                }
            }

          const toggleShow = () => {
            showPassword.value = !showPassword.value
          }

          const showLoginVideo = () => {
            //console.log('show video');
            if(displayLoginVideo.value) {
                displayLoginVideo.value = false;
            } else {
                displayLoginVideo.value = true;
            }
          }

          const closeLoginVideoTour = () => {
            if(displayLoginVideo.value) {
                displayLoginVideo.value = false;
            } else {
                displayLoginVideo.value = true;
            }
          }

          onMounted(() => {
            if(store.getters.getAutoLogoutState) {
                store.dispatch('setActiveModal', {modal: 'AutoLogoutConf'});
                store.dispatch('resetAutoLogout');
            }
          });

            const goToRoute = () => {
              if(route.query.path && validatePath(route.query.path)){
                router.push(route.query.path);
              }else {
                router.push('/');
              }
            }

            const validatePath = (inputPath) => {
              const routes = router.getRoutes();
              return routes.some(route => route.path === inputPath);
            };

            return {
                showLoginErrorMessage,
                email,
                password,
                twoFactor,
                mfaOne,
                mfaTwo,
                mfaThree,
                mfaFour,
                mfaFive,
                mfaSix,
                displayMFA,
                displayLoginVideo,
                codeError,
                errorMessage,
                usernameError,
                passwordError,
                showPassword,
                closeLoginError,
                closeCodeError,
                login,
                toggleModal,
                validateMFACode,
                nextCodeValue,
                resendComfirmationEmail,
                toggleShow,
                showLoginVideo,
                closeLoginVideoTour,
                loadingState,
                showModal
            }
        }
    }
</script>

<style scoped>
    #loginForm {
        width: 100%;
    }
    .codeErrorCard {
        margin: 0px 30px 0px 30px;
        padding: 30px 20px 30px 30px;
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
    }
    .mfaErrorTitle h3 {
        padding: 0px;
        margin: 0px !important;
        color: var(--clarity-red);
    }
    .mfaErrorTitle .material-icons {
        vertical-align: top;
        margin-right: 10px;
    }
    .mfaErrorMsg {
        margin-left: 37px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        font-size: 18px;
        color: var(--clarity-dark-grey);
    }
    .mfaCodeErrorInput {
        border: solid 1px #D1313880;
    }

    .forgottenPasswordLink {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .forgottenPasswordLink a {
        text-decoration: none;
        color: #8B96A2;
        font-size: 14px;
    }
    .twoFactorCheckBoxLabel {
        margin-top: 0px;
        color: #282B2F;
        font-size: 14px;
    }
    .notMember {
        margin-top: 45px;
        margin-bottom: 20px;
        color: #282B2F;
        font-size: 18px;
    }
    hr {
        height: 1px;
        border-width: 0;
        color: #DCE1E6;
        background-color: #DCE1E6;
    }
    .mfaCodeEntry {
        height: 154px;
        margin: 30px 0px 30px 0px !important;
        padding-top: 30px;
        padding-bottom: 35px;
        background-color: var(--clarity-snow-grey);
        text-align: center;
    }
    .mfaInputValue {
        text-align: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 18px;
        color: var(--clarity-grey);
    }
    .showMFABox {
        width: 600px;
    }
    .showMFABox p {
        margin-top: 20px;
    }
    .showMFABox h3 {
        margin: 36px 0px 0px 30px;
    }
    .showLoginVideoTour {
        width: 950px;
    }
    .showLoginVideoTour h3 {
        margin: 36px 0px 0px 30px;
    }
    .showLoginVideoTour p {
        margin: 0px 30px 0px 30px;
    }
    .loginVideo {
        margin: 30px;
    }
    .loginVideoDialog {
        margin-bottom: 35px;
    }
    .code-text-bold {
        font-weight: bold;
    }
    .clarity-close-modal-btn {
        width: 40px !important;
        height: 40px !important;
    }
    .actionBtns Button {
        margin-bottom: 35px;
    }
    .actionBtns button {
        padding: 15px 45px 15px 45px !important;
    }
    .field {
        margin-bottom: 0px !important;
        margin-top: 20px !important;
    }
    .loginHelpLinks p {
        margin: 0px !important;
    }
    .loginHelpClick {
        cursor: pointer;
    }
    .create-error-msg {
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
        margin-bottom: 30px;
        padding: 32px 30px 35px 30px;
    }
    .create-error-msg p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    .inputErr {
        margin-left: 20px;
        font-size: 14px;
        color: #D13138;
    }
    .material-icons {
        vertical-align: top;
        margin-right: 10px !important;
    }

    @media only screen and (max-width: 430px) {
        #loginForm {
            width: 400px !important;
        }
        .mfaInputValue {
            margin-left: 5px;
            margin-right: 5px;
        }
        .forgottenPasswordLink {
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 425px) {
        #loginForm {
            width: 335px !important;
        }
        .mfaInputValue {
            margin-left: 5px;
            margin-right: 5px;
        }
        .forgottenPasswordLink {
            margin-left: 0px;
        }
    }

    @media only screen and (max-width: 320px) {
        #loginForm {
            width: 310px !important;
        }
        .mfaInputValue {
            width: 40px;
            height: 40px;
            margin-left: 0px;
            margin-right: 0px;
        }
        .actionBtns Button {
            margin-bottom: 0px;
            margin-left: 2px !important;
            font-size: 16px !important;
            padding: 0px 10px !important;
        }
    }
</style>
